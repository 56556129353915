import React from "react"

import { Helmet } from "react-helmet"

import Navbar from "../components/navbar"
import Footer from "../components/footer"
import "./fees.css"

const Fees = (props) => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="fees-container">
      <Helmet>
        <title>Fees - Gina Wilson</title>
        <meta
          name="description"
          content="Gina Wilson is a New Zealand Naturopath specializing in children. "
        />
        <meta property="og:title" content="Fees - Gina Wilson" />
        <meta
          property="og:description"
          content="Gina Wilson is a New Zealand Naturopath specializing in children. "
        />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name3"></Navbar>
      <div className="fees-process">
        <h1 className="fees-text">The Process</h1>
        <div className="fees-container01">
          <div className="fees-step">
            <div className="fees-container02">
              <div className="fees-line"></div>
              <div className="fees-container03">
                <img
                  alt="image"
                  src="/playground_assets/gw%20icons_green_02-200h.png"
                  className="fees-image"
                />
              </div>
              <div className="fees-line1"></div>
            </div>
            <div className="fees-container04">
              <h1 className="fees-text01">Initial Consultation</h1>
              <span className="fees-text02">
                <span>
                  This is a 1 hour appointment (can be in-person or remote).
                </span>
                <br></br>
              </span>
            </div>
          </div>
          <div className="fees-step1">
            <div className="fees-container05">
              <div className="fees-line2"></div>
              <div className="fees-container06">
                <img
                  alt="image"
                  src="/playground_assets/gw%20icons_green_03-200h.png"
                  className="fees-image1"
                />
              </div>
              <div className="fees-line3"></div>
            </div>
            <div className="fees-container07">
              <h1 className="fees-text05">Treatment Plan</h1>
              <span className="fees-text06">
                Gina will develop a treatment plan for you.
              </span>
            </div>
          </div>
          <div className="fees-step2">
            <div className="fees-container08">
              <div className="fees-line4"></div>
              <div className="fees-container09">
                <img
                  alt="image"
                  src="/playground_assets/gw%20icons_green_01-200h.png"
                  className="fees-image2"
                />
              </div>
              <div className="fees-line5"></div>
            </div>
            <div className="fees-container10">
              <h1 className="fees-text07">Follow up Consultation</h1>
              <span className="fees-text08">
                Follow up consultation to asset treatment plan.
              </span>
            </div>
          </div>
          <div className="fees-step3">
            <div className="fees-container11">
              <div className="fees-line6"></div>
              <div className="fees-container12">
                <img
                  alt="image"
                  src="/playground_assets/gw%20icons_green_04-200h.png"
                  className="fees-image3"
                />
              </div>
              <div className="fees-line7"></div>
            </div>
            <div className="fees-container13">
              <h1 className="fees-text09">On-going Support</h1>
              <span className="fees-text10">
                Gina will be available to you for further discussions as your
                request.
              </span>
            </div>
          </div>
        </div>
      </div>
      <section className="fees-fees">
        <main className="fees-pricing">
          <header className="fees-header">
            <header className="fees-left">
              <span className="fees-section section-head">—</span>
              <h2 className="section-heading fees-heading">
                Fees and Information
              </h2>
            </header>
          </header>
          <div className="fees-plans-container">
            <main className="fees-plans">
              <div className="fees-plan">
                <div className="fees-details">
                  <div className="fees-header1">
                    <label className="fees-name">Initial Consult</label>
                    <div className="fees-pricing1">
                      <h1 className="fees-price">$240</h1>
                    </div>
                  </div>
                  <p className="fees-description">
                    <span>
                      This is a 1 hour appointment (can be in-person or remote).
                    </span>
                    <br></br>
                    <br></br>
                    <span>
                      Gina will review your health history and discuss relevant
                      lab investigations. 
                    </span>
                    <br></br>
                    <br></br>
                    <span>
                      Within 5 working days after the appointment you will
                      receive a written plan with the next steps.
                    </span>
                    <br></br>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: " ",
                        }}
                      />
                    </span>
                    <br></br>
                    <span>
                      A follow up appointment will be scheduled if required. 
                    </span>
                  </p>
                </div>
              </div>
              <div className="fees-plan1">
                <div className="fees-details1">
                  <div className="fees-header2">
                    <label className="fees-name1">Follow-up Consult</label>
                    <div className="fees-pricing2">
                      <h1 className="fees-price1">$180</h1>
                      <span className="fees-duration">/hr</span>
                    </div>
                  </div>
                  <p className="fees-description1">
                    <span>
                      3-5 weeks after initial consultation or on-going basis.
                    </span>
                    <br></br>
                    <br></br>
                    <span>
                      As your health improves the follow up time may reduce.
                    </span>
                    <br></br>
                  </p>
                </div>
              </div>
            </main>
          </div>
        </main>
        <span className="fees-text27">
          <span>
            Payment needs to be paid before the appointment. We will invoice you
            once booking is confirmed. If you are unable to make appointment
            please let us know within 24 hours of your appointment time for a
            refund, excluding a $20 booking fee.
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
          <br></br>
          <span>
            You will not be refunded your fee if you &quot;no show&quot;. 
          </span>
        </span>
      </section>
      <Footer rootClassName="footer-root-class-name3"></Footer>
    </div>
  )
}

export default Fees
