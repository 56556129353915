import React from "react"

import { Helmet } from "react-helmet"

import Navbar from "../components/navbar"
import FAQComponent from "../components/f-a-q-component"
import Footer from "../components/footer"
import "./f-a-q.css"

const FAQ = (props) => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="f-a-q-container">
      <Helmet>
        <title>FAQ - Gina Wilson</title>
        <meta
          name="description"
          content="Gina Wilson is a New Zealand Naturopath specializing in children. "
        />
        <meta property="og:title" content="FAQ - Gina Wilson" />
        <meta
          property="og:description"
          content="Gina Wilson is a New Zealand Naturopath specializing in children. "
        />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name4"></Navbar>
      <section className="f-a-q-section">
        <header className="f-a-q-header">
          <h2 className="f-a-q-heading section-heading">
            Frequently asked questions
          </h2>
        </header>
        <main className="f-a-q-accordion">
          <FAQComponent rootClassName="f-a-q-component-root-class-name5"></FAQComponent>
        </main>
      </section>
      <Footer rootClassName="footer-root-class-name1"></Footer>
    </div>
  )
}

export default FAQ
