import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"

import "./style.css"
import FAQ from "./views/f-a-q"
import About from "./views/about"
import Fees from "./views/fees"
import ClientFeedback from "./views/client-feedback"
import Page from "./views/page"
import Page1 from "./views/page1"
import Resources from "./views/resources"
import Contact from "./views/contact"
import Home from "./views/home"

const App = () => {
  return (
    <Router>
      <div>
        <Switch>
          <Route component={FAQ} exact path="/f-a-q" />
          <Route component={About} exact path="/about" />
          <Route component={Fees} exact path="/fees" />
          <Route component={ClientFeedback} exact path="/client-feedback" />
          <Route component={Resources} exact path="/resources" />
          <Route component={Contact} exact path="/contact" />
          <Route component={Home} exact path="/" />
          <Route component={Page} path="*" />
        </Switch>
      </div>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById("app"))
