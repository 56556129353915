import React from 'react'

import PropTypes from 'prop-types'

import './review.css'

const Review = (props) => {
  return (
    <section className={`review-card ${props.rootClassName} `}>
      <main className="review-content">
        <p className="review-quote">{props.Quote}</p>
        <div className="review-author">
          <div className="review-details">
            <h1 className="review-author1">{props.Author}</h1>
            <label className="review-position">{props.Year}</label>
          </div>
        </div>
      </main>
    </section>
  )
}

Review.defaultProps = {
  Quote:
    '“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.”',
  Author: 'Sima Mosbacher',
  rootClassName: '',
  Year: '2000',
}

Review.propTypes = {
  Quote: PropTypes.string,
  Author: PropTypes.string,
  rootClassName: PropTypes.string,
  Year: PropTypes.string,
}

export default Review
