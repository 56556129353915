import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Footer from '../components/footer'
import './page.css'

const Page = (props) => {
  return (
    <div className="page-container">
      <Helmet>
        <title>Page - Gina Wilson</title>
        <meta
          name="description"
          content="Gina Wilson is a New Zealand Naturopath specializing in children. "
        />
        <meta property="og:title" content="Page - Gina Wilson" />
        <meta
          property="og:description"
          content="Gina Wilson is a New Zealand Naturopath specializing in children. "
        />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name8"></Navbar>
      <section className="page-section2">
        <div className="page-note">
          <div className="page-content">
            <main className="page-main">
              <h2 className="page-heading">404 NOT FOUND</h2>
              <Link to="/" className="page-navlink">
                <p className="page-paragraph">
                  <span>Return Home</span>
                  <br></br>
                </p>
              </Link>
            </main>
          </div>
        </div>
      </section>
      <Footer rootClassName="footer-root-class-name5"></Footer>
    </div>
  )
}

export default Page
