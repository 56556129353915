import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './footer.css'

const Footer = (props) => {
  return (
    <footer className={`footer-footer ${props.rootClassName} `}>
      <div className="footer-green-break"></div>
      <div className="footer-content">
        <main className="footer-main-content">
          <div className="footer-content1">
            <header className="footer-main">
              <div className="footer-header">
                <img
                  alt={props.Branding_alt}
                  src="/playground_assets/gw%20logo-02-200h.png"
                  className="footer-branding"
                />
                <span className="footer-text">{props.Text}</span>
              </div>
              <div className="footer-socials">
                <a
                  href="https://example.com"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="footer-link"
                >
                  <img
                    alt={props.LinkedIn_alt}
                    src={props.LinkedIn_src}
                    className="social"
                  />
                </a>
                <a
                  href="https://example.com"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="footer-link1"
                >
                  <img
                    alt={props.Instagram_alt}
                    src={props.Instagram_src}
                    className="social"
                  />
                </a>
                <a
                  href="https://example.com"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="footer-link2"
                >
                  <img
                    alt={props.Twitter_alt}
                    src={props.Twitter_src}
                    className="social"
                  />
                </a>
              </div>
            </header>
            <header className="footer-categories">
              <div className="footer-category">
                <div className="footer-header1">
                  <Link to="/resources" className="footer-text01 footer-header">
                    {props.Text1}
                  </Link>
                </div>
                <div className="footer-links">
                  <Link to="/resources" className="footer-text02 footer-link">
                    {props.Text2}
                  </Link>
                  <Link to="/resources" className="footer-text03 footer-link">
                    {props.Text3}
                  </Link>
                  <Link to="/resources" className="footer-text04 footer-link">
                    {props.Text31}
                  </Link>
                  <Link to="/resources" className="footer-text05 footer-link">
                    {props.Text311}
                  </Link>
                </div>
              </div>
              <div className="footer-category1">
                <div className="footer-header2">
                  <span className="footer-text06 footer-header">
                    {props.Text7}
                  </span>
                </div>
                <div className="footer-links1">
                  <Link to="/about" className="footer-text07 footer-link">
                    {props.Text8}
                  </Link>
                  <Link
                    to="/client-feedback"
                    className="footer-text08 footer-link"
                  >
                    {props.Text9}
                  </Link>
                  <Link to="/fees" className="footer-text09 footer-link">
                    {props.Text10}
                  </Link>
                  <Link to="/f-a-q" className="footer-text10 footer-link">
                    {props.Text11}
                  </Link>
                  <Link to="/contact" className="footer-text11 footer-link">
                    {props.Text12}
                  </Link>
                </div>
              </div>
            </header>
          </div>
          <section className="footer-copyright">
            <span className="footer-text12">{props.text1}</span>
          </section>
        </main>
      </div>
    </footer>
  )
}

Footer.defaultProps = {
  Twitter_src: '/playground_assets/twitter-200h.png',
  Text12: 'Contact',
  text1: '© 2023 Gina Wilson. All Rights Reserved.',
  Text31: 'Australasion Support Organisations',
  Text3: 'New Zealand Autism Support Organisations',
  Instagram_src: '/playground_assets/instagram-200h.png',
  Branding_alt: 'logo',
  Text11: 'FAQ',
  LinkedIn_alt: 'image',
  Instagram_alt: 'image',
  Text9: 'Client Feedback',
  rootClassName: '',
  Text2: 'Books',
  Text311: 'General Resources',
  Text: '“The doctor of the future will give no medicine, but interest her or his patients in the care of the human frame, in proper diet and in the cause and prevention of disease.” - Thomas Edison',
  Branding_src: '6ed097d2-e667-4a14-9776-1d7101723932',
  LinkedIn_src: '/playground_assets/linkedin-200h.png',
  Text7: 'Sitemap',
  Text1: 'Resources',
  Text8: 'About',
  Text10: 'Fees',
  Twitter_alt: 'image',
}

Footer.propTypes = {
  Twitter_src: PropTypes.string,
  Text12: PropTypes.string,
  text1: PropTypes.string,
  Text31: PropTypes.string,
  Text3: PropTypes.string,
  Instagram_src: PropTypes.string,
  Branding_alt: PropTypes.string,
  Text11: PropTypes.string,
  LinkedIn_alt: PropTypes.string,
  Instagram_alt: PropTypes.string,
  Text9: PropTypes.string,
  rootClassName: PropTypes.string,
  Text2: PropTypes.string,
  Text311: PropTypes.string,
  Text: PropTypes.string,
  Branding_src: PropTypes.string,
  LinkedIn_src: PropTypes.string,
  Text7: PropTypes.string,
  Text1: PropTypes.string,
  Text8: PropTypes.string,
  Text10: PropTypes.string,
  Twitter_alt: PropTypes.string,
}

export default Footer
