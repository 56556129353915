import React from "react"

import { Helmet } from "react-helmet"

import Navbar from "../components/navbar"
import Review from "../components/review"
import ContactGina from "../components/contact-gina"
import Footer from "../components/footer"
import "./client-feedback.css"

const ClientFeedback = (props) => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="client-feedback-container">
      <Helmet>
        <title>Client-Feedback - Gina Wilson</title>
        <meta
          name="description"
          content="Gina Wilson is a New Zealand Naturopath specializing in children. "
        />
        <meta property="og:title" content="Client-Feedback - Gina Wilson" />
        <meta
          property="og:description"
          content="Gina Wilson is a New Zealand Naturopath specializing in children. "
        />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name2"></Navbar>
      <section className="client-feedback-testimonials">
        <header className="client-feedback-header">
          <header className="client-feedback-left">
            <span className="client-feedback-section section-head">
              Testimonials
            </span>
            <h2 className="client-feedback-heading section-heading">
              What Gina&apos;s clients say
            </h2>
          </header>
        </header>
        <main className="client-feedback-cards">
          <div className="client-feedback-container1">
            <Review
              Year="2019"
              Quote="Gina is an absolute powerhouse. I am so very grateful for having Gina on our team. My daughters biomedical journey began with Gina some years ago and we have never looked back. Gina is such a passionate naturopath, constantly upskilling and in hand educating me as a parent, ensuring I have the tools to make better informed choices towards wellness and health. I love her kind positive approach and how much she empowers the parents of her clients. Most of all, she believes in your child as much as you do!!!"
              Author="Amanda Wilson"
              rootClassName="review-root-class-name"
            ></Review>
          </div>
          <div className="client-feedback-container2">
            <Review
              Year="2019"
              Quote="Gina is amazing, kind, caring and has really helped me with both of my sons ongoing issues. Highly recommend for anyone considering a holistic approach to health and wellbeing for their children."
              Author="Emma Johns"
              rootClassName="review-root-class-name47"
            ></Review>
            <Review
              Year="2018"
              Quote="Gina has looked after both of my children that have autism. The results have been amazing. She is extremely caring and goes out of her way to help"
              Author="Anita Baldwin"
              rootClassName="review-root-class-name48"
            ></Review>
          </div>
          <div className="client-feedback-container3">
            <Review
              Year="2019"
              Quote="She is the best there is. intelligent, insightful, compassionate, and has been 100% correct about everything. We are so lucky to have her. Life changing"
              Author="Micaela Murray "
              rootClassName="review-root-class-name49"
            ></Review>
            <Review
              Year="2018"
              Quote="Gina is super clear about what will and wont work for different presentations, and different children. I would definitely recommend her for tricky and “unresolvable” issues – if she can’t fix it by golly she will try!"
              Author="Storm Baynes-Ryan "
              rootClassName="review-root-class-name50"
            ></Review>
          </div>
        </main>
      </section>
      <section className="client-feedback-section-first">
        <div className="client-feedback-note">
          <div className="client-feedback-content">
            <main className="client-feedback-main">
              <h2 className="client-feedback-heading1">
                A very happy Mum
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </h2>
              <p className="client-feedback-paragraph">
                <span>
                  Hi Gina, Well I can&apos;t believe 3 months have shot past! We
                  have seen a BIG change in G and I have to put it mostly down
                  to the strict GF side of things (maybe Soy free too). Our
                  usual diet has improved slightly on the vegie and nutritious
                  baking front but was pretty good already. After 2 weeks GF we
                  saw a slow but steady improvement in energy, behaviour, no
                  more yawning and fainty, pale child in mornings. No more
                  continuous nagging to get ready for school. One much more
                  relaxed Mum! G previously would never go and play outside
                  willingly, after one month on GF she shocked us one weekend by
                  getting dressed ! without being asked and headed out to the
                  trampoline straight after brekky. Went out 4 more times during
                  the same day for a 15 min bounce by herself!! Excuse all the
                  exclamation marks but this is very exciting. For the last 10
                  years I have had a difficult kid and even avoiding all
                  chemicals, excess sugar, dairy products and most gluten
                  containing food I knew she still wasn&apos;t quite right.THANK
                  YOU!!We had one muck up when she ate dumplings at an asian
                  friends house (little English spoken). All her old symptoms
                  came back and although I was mad about the mistake it was
                  probably a good reminder for her as to why she needs to be
                  careful.
                </span>
                <br className="client-feedback-text01"></br>
                <br></br>
                <span>J Greenidge.</span>
              </p>
            </main>
          </div>
          <div className="client-feedback-image">
            <img
              alt="image"
              src="/playground_assets/photo-1503454537195-1dcabb73ffb9-600w.jpg"
              className="client-feedback-image1"
            />
          </div>
        </div>
      </section>
      <section className="client-feedback-section2">
        <div className="client-feedback-note1">
          <div className="client-feedback-image2">
            <img
              alt="image"
              src="/playground_assets/photo-1593893513213-0ecc2ea282c5-900h.jpg"
              className="client-feedback-image3"
            />
          </div>
          <div className="client-feedback-content1">
            <main className="client-feedback-main1">
              <h2 className="client-feedback-heading2">
                Just the help that was needed
              </h2>
              <p className="client-feedback-paragraph1">
                <span>
                  I would like to offer my gratitude and thanks to Gina
                  (Naturopath) who offered us a wealth of knowledge and
                  information, and a successful out come when our baby had an
                  acute out break of eczema. At six months of age I introduced
                  solids to my baby and at ten months he was weened, during this
                  time he began to develop small persistent patches of eczema
                  which wouldn&apos;t clear. By the time he was eleven months
                  old he had an aggressive out break of weeping, itchy eczema
                  sores from head to toe. Gina explained that this was a build
                  up of toxins in the body due to food issues and that&apos;s
                  the body&apos;s way of trying to eradicate the over load.
                </span>
                <br className="client-feedback-text05"></br>
                <br></br>
                <span>
                  After three GP visits and two referrals to pediatric
                  dermatologists, we finally got diagnosis of a rare type of
                  Discoid Ecema.
                </span>
                <br className="client-feedback-text08"></br>
                <br></br>
                <span>
                  Gina came highly recommended to me and proved to be an
                  extremely professional and empathetic Naturopath, who&apos;s
                  extensive knowledge and training has proved to be an absolute
                  god send.
                </span>
                <br className="client-feedback-text11"></br>
                <br></br>
                <span>
                  Gina recommended some drastic dietary changes and a range of
                  natural supplements, and the results were highly visible
                  within the first few days of implementation. We noticed an
                  extreme improvement in not only our baby&apos;s skin but also
                  his happy little personality returned, he was no longer
                  agitated by the severe itching of his skin, he was able to
                  sleep through the night, and was generally so much more
                  settled.
                </span>
                <br className="client-feedback-text14"></br>
                <br></br>
                <span>
                  Eczema can bring huge stress to not only the little one
                  suffering from the condition, but also to parents who suffer
                  alongside their children, we are always continually searching
                  for a cure or treatment to bring back normality for our
                  children.
                </span>
                <br className="client-feedback-text17"></br>
                <br></br>
                <span>
                  So we would like to give our heartfelt thanks to Gina for
                  sharing wisdom with us, her passion for her work shines
                  through, and is an absolute credit to her, and as a family we
                  are immensely grateful for such a successful outcome for our
                  precious son.
                </span>
                <br className="client-feedback-text20"></br>
                <br></br>
                <span>L Yates</span>
              </p>
            </main>
          </div>
        </div>
      </section>
      <section className="client-feedback-section1">
        <main className="client-feedback-banner">
          <div className="client-feedback-header1">
            <h2 className="section-heading client-feedback-heading3">
              Get in touch with Gina!
            </h2>
          </div>
          <ContactGina rootClassName="contact-gina-root-class-name6"></ContactGina>
        </main>
      </section>
      <Footer rootClassName="footer-root-class-name2"></Footer>
    </div>
  )
}

export default ClientFeedback
