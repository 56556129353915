import React from "react"

import { Helmet } from "react-helmet"

import Navbar from "../components/navbar"
import Footer from "../components/footer"
import "./about.css"

const About = (props) => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="about-container">
      <Helmet>
        <title>About - Gina Wilson</title>
        <meta
          name="description"
          content="Gina Wilson is a New Zealand Naturopath specializing in children. "
        />
        <meta property="og:title" content="About - Gina Wilson" />
        <meta
          property="og:description"
          content="Gina Wilson is a New Zealand Naturopath specializing in children. "
        />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name6"></Navbar>
      <section className="about-section1">
        <div className="about-note">
          <div className="about-content">
            <main className="about-main">
              <h2 className="about-heading">About Gina</h2>
              <p className="about-paragraph">
                <span>
                  Gina Wilson is a registered Naturopath, specialising in
                  Pediatrics. She loves working on improving the health of
                  children via education, nutrition and practical help tailored
                  to meet the child&apos;s specific issues.
                </span>
                <br></br>
                <br></br>
                <span>
                  Gina has a special interest and experience in working with
                  children with diverse learning needs, behaviour and immune
                  issues. She works with many families with children with
                  Autism, ADHD and other diverse conditions.
                </span>
                <br className="about-text04"></br>
                <br></br>
                <span>
                  Gina undertakes a thorough dietary and lifestyle assessment
                  and designs a treatment plan that is tailored for each
                  individual. Gina holds amazing seminars on health with
                  international speakers and is regularly invited to speak at
                  ADHD groups, ASD support groups and Integrative Medical
                  Seminars.
                </span>
                <br className="about-text07"></br>
                <br></br>
              </p>
            </main>
          </div>
          <div className="about-image">
            <img
              alt="image"
              src="/playground_assets/gina-wilson-small-400w.jpg"
              className="about-image1"
            />
          </div>
        </div>
      </section>
      <section className="about-section2">
        <div className="about-note1">
          <div className="about-image2">
            <img
              alt="image"
              src="/playground_assets/theconnection00014_small-500h.jpg"
              className="about-image3"
            />
          </div>
          <div className="about-content1">
            <main className="about-main1">
              <h2 className="about-heading1">
                <span className="about-text09">The Connection</span>
                <br></br>
              </h2>
              <p className="about-paragraph1">
                <span>
                  Gina currently works in private practice as a Naturopath at
                  The Connection in Silverdale Auckland.
                </span>
                <br></br>
                <br></br>
                <span>
                  {" "}
                  Gina also has many clients across NZ and worldwide whom she
                  works with via online calls.
                </span>
              </p>
            </main>
            <div className="about-explore-more">
              <a
                href="https://theconnection.co.nz/"
                target="_blank"
                rel="noreferrer noopener"
                className="about-link"
              >
                <p className="about-text15">The Connection -&gt;</p>
              </a>
            </div>
          </div>
          <img
            alt="image"
            src="/playground_assets/theconnection00014_small-500h.jpg"
            className="about-image4"
          />
        </div>
      </section>
      <section className="about-section3">
        <div className="about-note2">
          <div className="about-content2">
            <main className="about-main2">
              <h2 className="about-heading2">
                <span className="about-text16">Experience</span>
                <br></br>
              </h2>
              <p className="about-paragraph2">
                Gina was very fortunate to work closely for years with Dr Leila
                Masson, an experienced Biomedical Pediatrician. Dr Masson has
                now left our shores but Gina continues working with other
                colleagues closely including Holistic GPs, Chiropractors,
                Homeopaths and other Naturopaths to ensure clients are supported
                and have the input they require.
              </p>
            </main>
            <div className="about-explore-more1">
              <a
                href="https://theconnection.co.nz/"
                target="_blank"
                rel="noreferrer noopener"
                className="about-link1"
              >
                <p className="about-text18">The Connection -&gt;</p>
              </a>
            </div>
          </div>
          <div className="about-image5">
            <img
              alt="image"
              src="/playground_assets/photo-1495446815901-a7297e633e8d-500h.jpg"
              className="about-image6"
            />
          </div>
        </div>
      </section>
      <section className="about-section4">
        <div className="about-note3">
          <div className="about-image7">
            <img
              alt="image"
              src="/playground_assets/photo-1634155938686-24a26c55d71a-400h.jpg"
              className="about-image8"
            />
          </div>
          <div className="about-content3">
            <main className="about-main3">
              <h2 className="about-heading3">
                <span className="about-text19">Seminars</span>
                <br></br>
              </h2>
              <p className="about-paragraph3">
                <span>
                  Gina has spoken at many events including the Gluten and
                  Allergy free show, MINDD seminar http://mindd.org, ACNEM
                  (Australasian College of Nutritional and Environmental
                  Medicine) and the Biomedical seminars in Auckland which she
                  also organises and runs.
                </span>
                <br></br>
                <span>
                  {" "}
                  She regularly posts new studies and interesting articles on
                  her Facebook page Gina Wilson Indaco Facebook Page
                </span>
              </p>
            </main>
            <div className="about-explore-more2">
              <a
                href="https://www.facebook.com/ginawilsonnaturopath/"
                target="_blank"
                rel="noreferrer noopener"
                className="about-link2"
              >
                <p className="about-text24">
                  Keep an eye on Facebook for upcoming seminars -&gt;
                </p>
              </a>
            </div>
          </div>
        </div>
      </section>
      <footer className="about-footer">
        <Footer rootClassName="footer-root-class-name"></Footer>
      </footer>
    </div>
  )
}

export default About
