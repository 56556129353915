import React from "react"
import { Link } from "react-router-dom"

import { Helmet } from "react-helmet"

import Navbar from "../components/navbar"
import ContactGina from "../components/contact-gina"
import Review from "../components/review"
import Footer from "../components/footer"
import "./home.css"

const Home = (props) => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="home-container">
      <Helmet>
        <title>Gina Wilson</title>
        <meta
          name="description"
          content="Gina Wilson is a New Zealand Naturopath specializing in children. "
        />
        <meta property="og:title" content="Gina Wilson" />
        <meta
          property="og:description"
          content="Gina Wilson is a New Zealand Naturopath specializing in children. "
        />
      </Helmet>
      <Navbar
        image_alt1="asdasd"
        rootClassName="navbar-root-class-name"
      ></Navbar>
      <section className="home-section1">
        <div className="home-hero">
          <img
            alt="image"
            src="/playground_assets/gina-wilson-small-500h.jpg"
            className="home-image"
          />
          <div className="home-content">
            <main className="home-main">
              <h1 className="home-heading">Gina Wilson</h1>
              <span className="home-text">
                <span className="home-text01">
                  Registered Naturopath &amp; Medical Herbalist,
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <br className="home-text02"></br>
                <span className="home-text03">Nutrition Consultant</span>
                <br></br>
                <br></br>
                <span>BHSc, B&apos;Ed Tchng, ND, DipHerbMed</span>
                <br></br>
              </span>
              <Link to="/contact" className="home-navlink">
                <ContactGina
                  rootClassName="contact-gina-root-class-name"
                  className="home-component1"
                ></ContactGina>
              </Link>
            </main>
          </div>
        </div>
      </section>
      <section className="home-section2">
        <div className="home-note">
          <div className="home-content1">
            <main className="home-main1">
              <h2 className="home-heading1">About Gina</h2>
              <p className="home-paragraph">
                <span>
                  Gina Wilson is a registered Naturopath, specialising in
                  Pediatrics. She loves working on improving the health of
                  children via education, nutrition and practical help tailored
                  to meet the child&apos;s specific issues.
                </span>
                <br className="home-text09"></br>
                <br></br>
                <span>
                  Gina has a special interest and experience in working with
                  children with diverse learning needs, behaviour and immune
                  issues. She works with many families with children with
                  Autism, ADHD and other diverse conditions.
                </span>
                <br className="home-text12"></br>
                <br></br>
                <span>
                  Gina undertakes a thorough dietary and lifestyle assessment
                  and designs a treatment plan that is tailored for each
                  individual. Gina holds amazing seminars on health with
                  international speakers and is regularly invited to speak at
                  ADHD groups, ASD support groups and Integrative Medical
                  Seminars.
                </span>
                <br></br>
              </p>
            </main>
            <div className="home-explore-more">
              <Link to="/about" className="home-navlink1">
                <p className="home-text16">More Information -&gt;</p>
              </Link>
            </div>
          </div>
          <div className="home-image1">
            <img
              alt="image"
              src="/playground_assets/photo-1501770118606-b1d640526693-500h.webp"
              className="home-image2"
            />
          </div>
        </div>
      </section>
      <section className="home-latest-news">
        <header className="home-header">
          <header className="home-left">
            <span className="home-section section-head">Facebook Feed</span>
            <h2 className="home-heading2 section-heading">Latest News</h2>
          </header>
        </header>
        <iframe
          src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fginawilsonnaturopath%2F&amp;tabs=timeline&amp;width=900&amp;height=500&amp;small_header=false&amp;adapt_container_width=true&amp;hide_cover=false&amp;show_facepile=true&amp;appId"
          width="500"
          scrolling="no"
          frameborder="0"
          className="home-iframe"
        ></iframe>
      </section>
      <section className="home-testimonials">
        <header className="home-header1">
          <header className="home-header2">
            <span className="home-text17 section-head">Testimonials</span>
            <h2 className="home-heading3 section-heading">
              <span>What Gina&apos;s clients say</span>
              <br></br>
            </h2>
          </header>
        </header>
        <main className="home-cards">
          <div className="home-container1">
            <Review
              Year="2019"
              Quote="Gina is an absolute powerhouse. I am so very grateful for having Gina on our team. My daughters biomedical journey began with Gina some years ago and we have never looked back. Gina is such a passionate naturopath, constantly upskilling and in hand educating me as a parent, ensuring I have the tools to make better informed choices towards wellness and health. I love her kind positive approach and how much she empowers the parents of her clients. Most of all, she believes in your child as much as you do!!!"
              Author="Amanda Wilson"
              rootClassName="review-root-class-name42"
            ></Review>
          </div>
          <div className="home-container2">
            <Review
              Year="2019"
              Quote="Gina is amazing, kind, caring and has really helped me with both of my sons ongoing issues. Highly recommend for anyone considering a holistic approach to health and wellbeing for their children."
              Author="Emma Johns"
              rootClassName="review-root-class-name43"
            ></Review>
            <Review
              Year="2018"
              Quote="Gina has looked after both of my children that have autism. The results have been amazing. She is extremely caring and goes out of her way to help"
              Author="Anita Baldwin"
              rootClassName="review-root-class-name44"
            ></Review>
          </div>
          <div className="home-container3">
            <Review
              Year="2019"
              Quote="She is the best there is. intelligent, insightful, compassionate, and has been 100% correct about everything. We are so lucky to have her. Life changing"
              Author="Micaela Murray "
              rootClassName="review-root-class-name45"
            ></Review>
            <Review
              Year="2018"
              Quote="Gina is super clear about what will and wont work for different presentations, and different children. I would definitely recommend her for tricky and “unresolvable” issues – if she can’t fix it by golly she will try!"
              Author="Storm Baynes-Ryan "
              rootClassName="review-root-class-name46"
            ></Review>
          </div>
        </main>
        <Link to="/client-feedback" className="home-navlink2">
          <div className="home-more-testimonials contact-gina">
            <span className="home-text20">More Client Feedback</span>
          </div>
        </Link>
      </section>
      <Footer></Footer>
    </div>
  )
}

export default Home
