import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './contact-gina.css'

const ContactGina = (props) => {
  return (
    <Link to="/contact" className="">
      <div
        className={`contact-gina-contact-gina contact-gina ${props.rootClassName} `}
      >
        <span className="contact-gina-text">Contact Gina</span>
      </div>
    </Link>
  )
}

ContactGina.defaultProps = {
  rootClassName: '',
}

ContactGina.propTypes = {
  rootClassName: PropTypes.string,
}

export default ContactGina
