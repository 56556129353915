import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './f-a-q-component.css'

const FAQComponent = (props) => {
  return (
    <div className={`f-a-q-component-accordion ${props.rootClassName} `}>
      <div
        data-role="accordion-container"
        className="f-a-q-component-element accordion-element"
      >
        <div className="f-a-q-component-details">
          <span className="f-a-q-component-text">What is Naturopathy?</span>
          <span
            data-role="accordion-content"
            className="f-a-q-component-text01"
          >
            The word means “Nature Cure” and is a method of healing that uses
            various natural means to empower an individual to achieve the
            highest level of health.
          </span>
        </div>
      </div>
      <div
        data-role="accordion-container"
        className="f-a-q-component-element01 accordion-element"
      >
        <div className="f-a-q-component-details01">
          <span className="f-a-q-component-text02">
            Why should I go to a Naturopath?
          </span>
          <span
            data-role="accordion-content"
            className="f-a-q-component-text03"
          >
            A naturopath focuses on treating the underlying causes of a health
            condition rather than the presenting symptoms.
          </span>
        </div>
      </div>
      <div
        data-role="accordion-container"
        className="f-a-q-component-element02 accordion-element"
      >
        <div className="f-a-q-component-details02">
          <span className="f-a-q-component-text04">Does Gina see adults?</span>
          <span
            data-role="accordion-content"
            className="f-a-q-component-text05"
          >
            Currently no, Gina specializes in working with children.
          </span>
        </div>
      </div>
      <div
        data-role="accordion-container"
        className="f-a-q-component-element03 accordion-element"
      >
        <div className="f-a-q-component-details03">
          <span className="f-a-q-component-text06">
            How long do I have to wait to see Gina?
          </span>
          <span
            data-role="accordion-content"
            className="f-a-q-component-text07"
          >
            Contact Gina to find out.
          </span>
        </div>
      </div>
      <div
        data-role="accordion-container"
        className="f-a-q-component-element04 accordion-element"
      >
        <div className="f-a-q-component-details04">
          <span className="f-a-q-component-text08">
            How frequently will I have to see Gina?
          </span>
          <span
            data-role="accordion-content"
            className="f-a-q-component-text09"
          >
            <span className="">
              It depends on the condition and health plan which we develop. In
              general Gina will see you for an initial 1 hour consult then again
              in 4-5 weeks for an additional hour.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className=""></br>
            <span className="">
              Each client is unique and Gina will work with you to develop an
              appropriate schedule.
            </span>
          </span>
        </div>
      </div>
      <div
        data-role="accordion-container"
        className="f-a-q-component-element05 accordion-element"
      >
        <div className="f-a-q-component-details05">
          <span className="f-a-q-component-text13">
            I want to come and see Gina, but what diet will she put me on?
          </span>
          <span
            data-role="accordion-content"
            className="f-a-q-component-text14"
          >
            {' '}
            Gina will develop an eating plan for you after your first visit.
            This plan will be tailored to your needs.
          </span>
        </div>
      </div>
      <div
        data-role="accordion-container"
        className="f-a-q-component-element06 accordion-element"
      >
        <div className="f-a-q-component-details06">
          <span className="f-a-q-component-text15">
            Why does Gina often promote a gluten and dairy free diet?
          </span>
          <span
            data-role="accordion-content"
            className="f-a-q-component-text16"
          >
            <span className="">
              Gluten is the protein found in wheat, barley, oats, rye, spelt,
              triticale and kamut.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className=""></br>
            <span className="">
              Casein is a protein found in all animal milk and products made
              from milk.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className=""></br>
            <span className="">
              Eliminating these two proteins is often an important first step to
              dietary intervention. Many children benefit from eliminating
              gluten and casein. The primary reason to eliminate both proteins
              from the diet of children with autism especially is because the
              two proteins break down into opiate like peptides. These peptides
              are not able to be absorbed in a healthy gut, but in an inflamed
              gut they can. The peptides can attach to opiate receptors in the
              body and can result in symptoms such as “zoning out”, repetitive
              behaviour, sleep disturbance, constipation, high pain tolerance
              and finicky eating habits.
            </span>
          </span>
        </div>
      </div>
      <div
        data-role="accordion-container"
        className="f-a-q-component-element07 accordion-element"
      >
        <div className="f-a-q-component-details07">
          <span className="f-a-q-component-text22">
            Why do I need to be careful of pesticides and other toxins?
          </span>
          <span
            data-role="accordion-content"
            className="f-a-q-component-text23"
          >
            Pesticides and a range of other toxins have been shown to disrupt
            neurological function, immunity, the endocrine system and can cause
            skin gastrointestinal, urinary, genital, respiratory, and
            musculoskeletal problems.
          </span>
        </div>
      </div>
      <div
        data-role="accordion-container"
        className="f-a-q-component-element08 accordion-element"
      >
        <div className="f-a-q-component-details08">
          <span className="f-a-q-component-text24">
            Why should I lower sugar intake in my diet?
          </span>
          <span
            data-role="accordion-content"
            className="f-a-q-component-text25"
          >
            <span className="">
              Sugar is associated with hyperactivity, depression, moodiness and
              suppressed immunity.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className=""></br>
            <span className="">
              The World Health Organisation&apos;s recommend daily intake for
              sugar is around 6 teaspoons. Unfortunately our modern diets tend
              to have more than 15 to 20 teaspoons a day.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <a
              href="http://www.who.int/mediacentre/news/releases/2015/sugar-guideline/en/"
              target="_blank"
              rel="noreferrer noopener"
              className="f-a-q-component-link"
            >
              http://www.who.int/mediacentre/news/releases/2015/sugar-guideline/en/
            </a>
            <br className=""></br>
            <span className="">
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </span>
        </div>
      </div>
      <div
        data-role="accordion-container"
        className="f-a-q-component-element09 accordion-element"
      >
        <div className="f-a-q-component-details09">
          <span className="f-a-q-component-text31">
            I need Gina urgently, how can I contact her?
          </span>
          <span
            data-role="accordion-content"
            className="f-a-q-component-text32"
          >
            <span className="f-a-q-component-text33">
              If you have a medical emergency you should call 111 and not Gina.
            </span>
            <br className=""></br>
            <br className=""></br>
            <span className="">For non emergency treatment you can:</span>
            <br className=""></br>
            <span className="">
              - Call Healthline on 0800 611 116 for free advice from trained
              registered nurses.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <a
              href="http://www.health.govt.nz/your-health/services-and-support/health-care-services/healthline."
              target="_blank"
              rel="noreferrer noopener"
              className="f-a-q-component-link1"
            >
              http://www.health.govt.nz/your-health/services-and-support/health-care-services/healthline.
            </a>
            <br className=""></br>
            <span className="">- Consult your General Practitioner</span>
            <br className=""></br>
            <br className=""></br>
            <span className="">
              Gina is always available via email at
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <a
              href="mailto:gina@indaco.co.nz?subject="
              className="f-a-q-component-link2"
            >
              gina@indaco.co.nz
            </a>
            <span className="">
               
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className=""></br>
            <span className="">
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </span>
        </div>
      </div>
      <div
        data-role="accordion-container"
        className="f-a-q-component-element10 accordion-element"
      >
        <div className="f-a-q-component-details10">
          <span className="f-a-q-component-text47">
            I suspect my child has an ear infection, should I contact Gina?
          </span>
          <span
            data-role="accordion-content"
            className="f-a-q-component-text48"
          >
            <span className="">
              {' '}
              If your child is acutely unwell consult with your General
              Practitioner.
            </span>
            <br className=""></br>
            <span className="">
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </span>
        </div>
      </div>
      <div
        data-role="accordion-container"
        className="f-a-q-component-element11 accordion-element"
      >
        <div className="f-a-q-component-details11">
          <span className="f-a-q-component-text52">
            I want to investigate my genes. Can Gina help me?
          </span>
          <span
            data-role="accordion-content"
            className="f-a-q-component-text53"
          >
            <span className="">
              {' '}
              Gina offers epigenetic testing. She can then provide a personal
              genetic profile showing any variations you may have in your genes,
              which in turn, can influence your health and nutrition.
            </span>
            <br className=""></br>
            <br className=""></br>
            <span className="">
              {' '}
              Nutrigenomics is a scientific discipline that studies the
              interaction between genes, diet and lifestyle choices. These
              choices can influence your health, wellbeing and performance. By
              eating the appropriate food for you, informed by genetic testing,
              you can improve your health. 
            </span>
            <br className=""></br>
            <span className="">
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </span>
        </div>
      </div>
      <div
        data-role="accordion-container"
        className="f-a-q-component-element12 accordion-element"
      >
        <div className="f-a-q-component-details12">
          <span className="f-a-q-component-text60">
            What resources do you recommend I read?
          </span>
          <span
            data-role="accordion-content"
            className="f-a-q-component-text61"
          >
            <span className="">
              Refer to the
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <Link to="/resources" className="f-a-q-component-navlink">
              resources page
            </Link>
            <span className=""> for more information.</span>
            <br className=""></br>
            <span className="">
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}

FAQComponent.defaultProps = {
  rootClassName: '',
}

FAQComponent.propTypes = {
  rootClassName: PropTypes.string,
}

export default FAQComponent
