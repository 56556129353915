import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './navbar.css'

const Navbar = (props) => {
  return (
    <nav className={`navbar-navbar ${props.rootClassName} `}>
      <header data-thq="thq-navbar" className="navbar-navbar-interactive">
        <div className="navbar-container">
          <Link to="/" className="navbar-navlink">
            <img
              alt="logo"
              src="/playground_assets/gw%20logo_small-200h.png"
              loading="lazy"
              className="navbar-logo"
            />
          </Link>
        </div>
        <div
          data-thq="thq-navbar-nav"
          data-role="Nav"
          className="navbar-desktop-menu"
        >
          <div className="navbar-nav-content">
            <div className="navbar-nav-links">
              <Link to="/" className="navbar-link nav-link">
                <span className="">Home</span>
                <br className=""></br>
              </Link>
              <Link to="/about" className="navbar-link1 nav-link">
                About
              </Link>
              <Link to="/client-feedback" className="navbar-link2 nav-link">
                Client Feedback
              </Link>
              <Link to="/fees" className="navbar-link3 nav-link">
                Fees
              </Link>
              <Link to="/f-a-q" className="navbar-link4 nav-link">
                FAQ
              </Link>
            </div>
            <Link to="/contact" className="navbar-navlink1">
              <div className="navbar-contact-gina contact-gina">
                <span className="navbar-text2">Contact Gina</span>
              </div>
            </Link>
          </div>
        </div>
        <div
          data-thq="thq-burger-menu"
          className="navbar-burger-menu contact-gina"
        >
          <img
            alt={props.image_alt211}
            src={props.image_src211}
            className="navbar-image"
          />
        </div>
        <div data-thq="thq-mobile-menu" className="navbar-mobile-menu">
          <div
            data-thq="thq-mobile-menu-nav"
            data-role="Nav"
            className="navbar-nav"
          >
            <div className="navbar-container1">
              <img
                alt="image"
                src="/playground_assets/gw%20logo_small-200h.png"
                className="navbar-image1"
              />
              <div data-thq="thq-close-menu" className="navbar-menu-close">
                <svg viewBox="0 0 1024 1024" className="navbar-icon">
                  <path
                    d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
                    className=""
                  ></path>
                </svg>
              </div>
            </div>
            <nav
              data-thq="thq-mobile-menu-nav-links"
              data-role="Nav"
              className="navbar-nav1"
            >
              <Link to="/" className="navbar-navlink2">
                <span className="navbar-text3">Home</span>
                <br className=""></br>
              </Link>
              <Link to="/about" className="navbar-navlink3">
                About
              </Link>
              <Link to="/client-feedback" className="navbar-navlink4">
                Client Feedback
              </Link>
              <Link to="/fees" className="navbar-navlink5">
                Fees
              </Link>
              <Link to="/f-a-q" className="navbar-navlink6">
                FAQ
              </Link>
            </nav>
          </div>
          <div className="navbar-icon-group">
            <a
              href="https://www.facebook.com/ginawilsonnaturopath/"
              target="_blank"
              rel="noreferrer noopener"
              className="navbar-link5"
            >
              <svg
                viewBox="0 0 602.2582857142856 1024"
                className="navbar-icon2"
              >
                <path
                  d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"
                  className=""
                ></path>
              </svg>
            </a>
          </div>
        </div>
      </header>
      <div className="navbar-green-break"></div>
    </nav>
  )
}

Navbar.defaultProps = {
  image_alt211: 'image',
  rootClassName12: '',
  rootClassName: '',
  image_src211: '/playground_assets/hamburger-200h.png',
}

Navbar.propTypes = {
  image_alt211: PropTypes.string,
  rootClassName12: PropTypes.string,
  rootClassName: PropTypes.string,
  image_src211: PropTypes.string,
}

export default Navbar
