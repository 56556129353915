import React from 'react'

import PropTypes from 'prop-types'

import './resources-component.css'

const ResourcesComponent = (props) => {
  return (
    <div className={`resources-component-accordion ${props.rootClassName} `}>
      <div
        data-role="accordion-container"
        className="resources-component-element accordion-element"
      >
        <div className="resources-component-details">
          <span className="resources-component-text">Books</span>
          <div className="resources-component-container">
            <a
              href="https://www.amazon.com/Healing-New-Childhood-Epidemics-Groundbreaking/dp/0345494512"
              target="_blank"
              rel="noreferrer noopener"
              className="resources-component-link"
            >
              <img
                alt={props.image_alt}
                src={props.image_src}
                className="resources-component-image"
              />
            </a>
            <a
              href="https://www.amazon.com/Children-Starving-Brains-Treatment-Spectrum/dp/1883647177"
              target="_blank"
              rel="noreferrer noopener"
              className="resources-component-link01"
            >
              <img
                alt={props.image_alt1}
                src={props.image_src1}
                className="resources-component-image1"
              />
            </a>
            <a
              href="https://www.amazon.com/Changing-Course-Autism-Scientific-Physicians/dp/1591810612"
              target="_blank"
              rel="noreferrer noopener"
              className="resources-component-link02"
            >
              <img
                alt={props.image_alt2}
                src={props.image_src2}
                className="resources-component-image2"
              />
            </a>
            <a
              href="https://www.amazon.com/Body-Ecology-Diet-Recovering-Rebuilding/dp/1401935435"
              target="_blank"
              rel="noreferrer noopener"
              className="resources-component-link03"
            >
              <img
                alt={props.image_alt3}
                src={props.image_src3}
                className="resources-component-image3"
              />
            </a>
            <a
              href="https://www.amazon.com/Chemical-Maze-Additives-Cosmetic-Ingredients/dp/1840244828"
              target="_blank"
              rel="noreferrer noopener"
              className="resources-component-link04"
            >
              <img
                alt={props.image_alt4}
                src={props.image_src4}
                className="resources-component-image4"
              />
            </a>
            <a
              href="https://www.amazon.com/Special-Diets-Kids-Understanding-Developmental/dp/1885477449"
              target="_blank"
              rel="noreferrer noopener"
              className="resources-component-link05"
            >
              <img
                alt={props.image_alt5}
                src={props.image_src5}
                className="resources-component-image5"
              />
            </a>
            <a
              href="https://www.amazon.com/Fed-Up-Sue-Dengate/dp/1741667259"
              target="_blank"
              rel="noreferrer noopener"
              className="resources-component-link06"
            >
              <img
                alt={props.image_alt7}
                src={props.image_src7}
                className="resources-component-image6"
              />
            </a>
            <a
              href="https://www.amazon.com/Psychology-Syndrome-D-D-D-H-D-Schizophrenia/dp/0954852028"
              target="_blank"
              rel="noreferrer noopener"
              className="resources-component-link07"
            >
              <img
                alt={props.image_alt6}
                src={props.image_src6}
                className="resources-component-image7"
              />
            </a>
          </div>
        </div>
      </div>
      <div
        data-role="accordion-container"
        className="resources-component-element1 accordion-element"
      >
        <div className="resources-component-details1">
          <span className="resources-component-text01">
            New Zealand Autism Support Organisations
          </span>
          <span
            data-role="accordion-content"
            className="resources-component-text02"
          >
            <span className="">Children’s Autism Foundation</span>
            <br className=""></br>
            <a
              href="https://www.autism.org.nz/"
              target="_blank"
              rel="noreferrer noopener"
              className="resources-component-link08"
            >
              https://www.autism.org.nz/
            </a>
            <br className=""></br>
            <span className="">Phone: 09 415 7406</span>
            <br className=""></br>
            <br className=""></br>
            <span className="">-</span>
            <br className=""></br>
            <br className=""></br>
            <span className="">Autism New Zealand Inc</span>
            <br className=""></br>
            <a
              href="https://www.autismnz.org.nz/"
              target="_blank"
              rel="noreferrer noopener"
              className="resources-component-link09"
            >
              https://www.autismnz.org.nz/
            </a>
            <br className=""></br>
            <span className="">Email: admin@autism.org.nz</span>
            <br className=""></br>
          </span>
        </div>
      </div>
      <div
        data-role="accordion-container"
        className="resources-component-element2 accordion-element"
      >
        <div className="resources-component-details2">
          <span className="resources-component-text17">
            Australasion Support Organisations
          </span>
          <span
            data-role="accordion-content"
            className="resources-component-text18"
          >
            <span className="">Food Intolerance Network</span>
            <br className=""></br>
            <a
              href="https://fedup.com.au/"
              target="_blank"
              rel="noreferrer noopener"
              className="resources-component-link10"
            >
              https://fedup.com.au/
            </a>
            <br className=""></br>
            <br className=""></br>
            <span className="">-</span>
            <br className=""></br>
            <br className=""></br>
            <span className="">MINDD Australia</span>
            <br className=""></br>
            <a
              href="https://mindd.org/"
              target="_blank"
              rel="noreferrer noopener"
              className="resources-component-link11"
            >
              https://mindd.org/
            </a>
            <br className=""></br>
            <br className=""></br>
            <span className="">
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </span>
        </div>
      </div>
      <div
        data-role="accordion-container"
        className="resources-component-element3 accordion-element"
      >
        <div className="resources-component-details3">
          <span className="resources-component-text31">General Resources</span>
          <span
            data-role="accordion-content"
            className="resources-component-text32"
          >
            <span className="">Talk About Curing Autism</span>
            <br className=""></br>
            <a
              href="https://www.tacanow.org/category/family-stories/recovered-from-autism/"
              target="_blank"
              rel="noreferrer noopener"
              className="resources-component-link12"
            >
              https://www.tacanow.org/category/family-stories/recovered-from-autism/
            </a>
          </span>
        </div>
      </div>
    </div>
  )
}

ResourcesComponent.defaultProps = {
  image_src5: '/playground_assets/book6-200w.webp',
  image_src: '/playground_assets/book1-200w.png',
  image_alt5: 'image',
  image_src7: '/playground_assets/book8-200w.webp',
  image_alt3: 'image',
  image_src4: '/playground_assets/book5-200w.webp',
  image_alt6: 'image',
  image_src6: '/playground_assets/book7-200w.webp',
  image_alt2: 'image',
  image_alt4: 'image',
  image_src2: '/playground_assets/book3-200w.png',
  rootClassName: '',
  image_src1: '/playground_assets/book2-200w.png',
  image_src3: '/playground_assets/book4-200w.webp',
  image_alt7: 'image',
  image_alt1: 'image',
  image_alt: 'image',
}

ResourcesComponent.propTypes = {
  image_src5: PropTypes.string,
  image_src: PropTypes.string,
  image_alt5: PropTypes.string,
  image_src7: PropTypes.string,
  image_alt3: PropTypes.string,
  image_src4: PropTypes.string,
  image_alt6: PropTypes.string,
  image_src6: PropTypes.string,
  image_alt2: PropTypes.string,
  image_alt4: PropTypes.string,
  image_src2: PropTypes.string,
  rootClassName: PropTypes.string,
  image_src1: PropTypes.string,
  image_src3: PropTypes.string,
  image_alt7: PropTypes.string,
  image_alt1: PropTypes.string,
  image_alt: PropTypes.string,
}

export default ResourcesComponent
