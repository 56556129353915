import React from "react"

import { Helmet } from "react-helmet"

import Navbar from "../components/navbar"
import Footer from "../components/footer"
import "./contact.css"

const Contact = (props) => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="contact-container">
      <Helmet>
        <title>Contact - Gina Wilson</title>
        <meta
          name="description"
          content="Gina Wilson is a New Zealand Naturopath specializing in children. "
        />
        <meta property="og:title" content="Contact - Gina Wilson" />
        <meta
          property="og:description"
          content="Gina Wilson is a New Zealand Naturopath specializing in children. "
        />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name5"></Navbar>
      <section className="contact-section2">
        <div className="contact-note">
          <div className="contact-content">
            <main className="contact-main">
              <h2 className="contact-heading">Contact Gina</h2>
              <p className="contact-paragraph">
                <span className="contact-text">Email:</span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <a href="mailto:gina@indaco.co.nz?subject=Contact Gina">
                  gina@indaco.co.nz
                </a>
                <br></br>
                <span className="contact-text3">Phone: </span>
                <a href="tel:+640211326561" className="contact-link1">
                  021 132 6561
                </a>
                <br></br>
              </p>
            </main>
          </div>
          <div className="contact-image">
            <img
              alt="image"
              src="/playground_assets/gina-wilson-small-600h.jpg"
              className="contact-image1"
            />
          </div>
        </div>
      </section>
      <Footer rootClassName="footer-root-class-name4"></Footer>
    </div>
  )
}

export default Contact
