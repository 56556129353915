import React from "react"

import { Helmet } from "react-helmet"

import Navbar from "../components/navbar"
import ResourcesComponent from "../components/resources-component"
import Footer from "../components/footer"
import "./resources.css"

const Resources = (props) => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="resources-container">
      <Helmet>
        <title>Resources - Gina Wilson</title>
        <meta
          name="description"
          content="Gina Wilson is a New Zealand Naturopath specializing in children. "
        />
        <meta property="og:title" content="Resources - Gina Wilson" />
        <meta
          property="og:description"
          content="Gina Wilson is a New Zealand Naturopath specializing in children. "
        />
      </Helmet>
      <Navbar
        image_alt1="asdasd"
        rootClassName="navbar-root-class-name7"
      ></Navbar>
      <section className="resources-resources-section1">
        <header className="resources-header">
          <header className="resources-left">
            <h2 className="resources-heading section-heading">Resources</h2>
          </header>
        </header>
        <ResourcesComponent rootClassName="resources-component-root-class-name"></ResourcesComponent>
      </section>
      <Footer></Footer>
    </div>
  )
}

export default Resources
